//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiOrderBooking from "../../../../../app/api/authorized/admin/order/ApiOrderBookings";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import Select from "../../../../components/inputs/select"
import InputText from "../../../../components/inputs/text";
import InputPhone from "../../../../components/inputs/phone/phone";
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import InputTextArea from "../../../../components/inputs/textarea/textarea";
import InputNumber from "../../../../components/inputs/number/number";
import ShowWrap from "../../../../components/wrappers/show-wrap";
import ShowWrapItem from "../../../../components/wrappers/show-wrap-item";
//Стили
import './styles.css';
import '../../../styles.css';


export default function OrderBookingShow({ ...other }) {
    return <Page {...other} ApiModel={ApiOrderBooking}>
        <OrderBookingShowContent {...other} />
    </Page>
}

function OrderBookingShowContent({ ...other }) {
    const contextPage = useContext(ContextPage);
    const contextContent = useContext(ContextContent);
    const [_id, set_id] = useState(null);
    const [_name, set_name] = useState('');
    const [_phone, set_phone] = useState('');
    const [_email, set_email] = useState('');
    const [_productId, set_productId] = useState('');
    const [_payStatusId, set_payStatusId] = useState('');
    const [_count, set_count] = useState(1);
    const [_comment, set_comment] = useState('');

    const [_code, set_code] = useState('');
    const [_promocode, set_promocode] = useState('');
    const [_sum, set_sum] = useState(null);
    const [_total, set_total] = useState(null);
    const [_payDate, set_payDate] = useState(null);

    const [_created, set_created] = useState(null);
    const [_updated, set_updated] = useState(null);

    const products = useSelector(state => state.adminOption.products);
    const paymentStatuses = useSelector(state => state.commonOption.paymentStatuses);

    const handleProductChange = (event) => {
        set_productId(event.target.value);
    };

    const handlePaymenthange = (event) => {
        set_payStatusId(event.target.value);
    };

    contextContent._dataToServer.current = {
        name: _name,
        phone: _phone,
        email: _email,
        product_id: _productId,
        comment: _comment,
        count: _count,
        payment: _payStatusId,
    };
    useLayoutEffect(() => {
        contextContent._handleSetData.current = (data) => {
            set_id(data.id);
            set_name(data.name);
            set_phone(data.phone);
            set_email(data.email);
            set_code(data.code)
            set_productId(data.product);
            set_promocode(data.promocode_name)
            set_payStatusId(data.payment_id)
            set_comment(data.comment);
            set_sum(data.sum);
            set_total(data.total);
            set_payDate(data.date_pay);
            set_created(data.created_at);
            set_updated(data.updated_at);
            contextPage.setTitle(`Полетное время: ${data.name}`);
            contextContent.setTitle(`Полетное время: ${data.name}`);
            contextPage.setName(data.name);
        };
        contextContent.doLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <>
    <ShowWrap>
        <ShowWrapItem>
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputNumber className="pages__show-input" value={_id} readOnly={true}>Номер</InputNumber>
                </InputWrapper>
                : null}
            <InputWrapper>
                <InputText className="pages__show-input" value={_name} readOnly={!contextContent.isCreate} onChange={e => set_name(e.target.value)}>Имя</InputText>
            </InputWrapper>
            <InputWrapper>
                <InputPhone className="pages__show-input" value={_phone} readOnly={!contextContent.isCreate} onChange={e => set_phone(e.target.value)}>Телефон</InputPhone>
            </InputWrapper>
            <InputWrapper>
                <InputText className="pages__show-input" value={_email} readOnly={!contextContent.isCreate} onChange={e => set_email(e.target.value)}>Почта</InputText>
            </InputWrapper>
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputText className="pages__show-input" value={_code} readOnly={true}>Номер</InputText>
                </InputWrapper>
                : null}
            <InputWrapper>
                <Select className="pages__show-input" options={products} value={_productId} readOnly={!contextContent.isCreate} onChange={handleProductChange}>Продукт</Select>
            </InputWrapper>
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputText className="pages__show-input" value={_promocode} readOnly={true}>Промокод</InputText>
                </InputWrapper>
                : null}
            <InputWrapper>
                <Select className="pages__show-input" options={paymentStatuses} value={_payStatusId} readOnly={!contextContent.isCreate} onChange={handlePaymenthange}>Статус оплаты</Select>
            </InputWrapper>
        </ShowWrapItem>
        <ShowWrapItem>
            <InputWrapper>
                <InputTextArea className="pages__show-input" value={_comment} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_comment(e.target.value)}>Комментарий</InputTextArea>
            </InputWrapper>
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputText className="pages__show-input" value={_sum} readOnly={true}>Сумма, ₽</InputText>
                </InputWrapper>
                : null}
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputText className="pages__show-input" value={_total} readOnly={true}>Итог, ₽</InputText>
                </InputWrapper>
                : null}
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputDatetime className="pages__show-input" value={_payDate} readOnly={true}>Дата оплаты</InputDatetime>
                </InputWrapper>
                : null}
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
                </InputWrapper>
                : null}
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
                </InputWrapper>
                : null}
        </ShowWrapItem>
    </ShowWrap>
    </>
}
