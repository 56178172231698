//Реакт
import React, { useState, useLayoutEffect, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiAnalytic from "../../../../../../app/api/authorized/admin/statistic/ApiBookingAnalytic";
//Модули Content
import Page from "../../../../../modules/page";
import { ContextPage } from "../../../../../modules/page/pageContext";
import { ContextContent } from "../../../../../modules/content/contentContext";
//Компоненты
import Filters from "../../../../../modules/filters";
import MultiSelect from "../../../../../components/inputs/milti-select";
import UniversalChart from "../../../../../components/chart";
import InputDateTime from "../../../../../components/inputs/datetime"
//Стили
import './styles.css';

export default function AnalyticBookingIndex({ ...other }) {
    return (
        <Page {...other}>
            <AnalyticBookingIndexContent {...other} />
        </Page>
    );
}

function AnalyticBookingIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);
    const [_lineChart, set_lineChart] = useState({});
    const [_totalInfo, set_totalInfo] = useState(
        {
            sum_and_count: {
                count: 0,
                minutes: 0,
            },
            first_time_bookings: {
                count: 0,
                minutes: 0,
            },
            sport_time_bookings: {
                count: 0,
                minutes: 0,
            },
            minutes_promo: {
                count: 0,
                minutes: 0,
            },
        }
    )
    const [_dateStart, set_dateStart] = useState('');
    const [_dateEnd, set_dateEnd] = useState('');
    const [_productIds, set_productIds] = useState([]);
    const [_productCategoryIds, set_productCategoryIds] = useState([]);
    const [_paymentTypeIds, set_paymentTypeIds] = useState([]);
    const [_promocodeIds, set_promocodeIds] = useState([]);

    const handlePaymentStatusChange = (event) => {
        set_paymentTypeIds(event.target.value);
    }

    const handlePromocodesChange = (event) => {
        set_promocodeIds(event.target.value);
    }

    const handleProductsChange = (event) => {
        set_productIds(event.target.value);
    }

    const handleProductCategoriesChange = (event) => {
        set_productCategoryIds(event.target.value);
    }
    const promocodes = useSelector(state => state.adminOption.promocodes);
    const products = useSelector(state => state.adminOption.products);
    const productCategories = useSelector(state => state.adminOption.productCategories);
    const paymentTypes = useSelector(state => state.commonOption.paymentTypes);

    const linaeChartOptions = {
        title: 'Продажи по дням',
        hAxis: { title: 'Дата', titleTextStyle: { color: '#333' } },
        vAxis: { title: 'Сумма', titleTextStyle: { color: '#333' },  minValue: 0 },
        chartArea: { width: '85%', height: '90%' },
    };


    const prepareLineChartData = (chartData) => {
        if (!chartData) return [["Дата", "Минуты"]]; // Возвращаем пустую таблицу с заголовками
        // Преобразовываем данные в массив объектов, который можно использовать для построения линейного графика
        let data = Object.entries(chartData).map(([date, data]) => [
            date, // Дата
            data?.minutes ?? 0, // Общая сумма
        ]);
        // Добавляем заголовки столбцов в начало массива
        data.unshift(["Дата", "Минуты"]);
        return data;
    };

    useLayoutEffect(() => {
        contextPage.setTitle('Аналитика (бронирования)');
        contextContent.setTitle('Аналитика (бронирования)');
    }, [contextContent, contextPage]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiIndex = ApiAnalytic.Index({dateStart: _dateStart, 
                    dateEnd: _dateEnd, products: _productIds, promocodes: _promocodeIds,
                    paymentTypes: _paymentTypeIds, categories: _productCategoryIds
                });
                const responseData = await apiIndex.getResponse();
                const bookings = responseData.bookings ?? null;
                set_lineChart(responseData.line_chart ?? null);
                set_totalInfo(
                    {
                        sum_and_count: {
                            count: bookings?.sum_and_count?.count,
                            minutes: bookings?.sum_and_count?.minutes,
                        },
                        first_time_bookings: {
                            count: bookings?.first_time_bookings?.count,
                            minutes: bookings?.first_time_bookings?.minutes,
                        },
                        sport_time_bookings: {
                            count:  bookings?.sport_time_bookings?.count,
                            minutes: bookings?.sport_time_bookings?.minutes,
                        },
                        minutes_promo: {
                            count: bookings?.minutes_promo?.count,
                            minutes: bookings?.minutes_promo?.minutes,
                        },
                    }
                )
            } catch (error) {}
        };

        fetchData();
    }, [_dateStart, _dateEnd, _productIds, _promocodeIds, _paymentTypeIds, _productCategoryIds]);

    const filters = [
        <InputDateTime value={_dateStart} className="input_wf" onChange={(e) => { set_dateStart(e.target.value) }}>Дата создания с </InputDateTime>,
        <InputDateTime value={_dateEnd} className="input_wf" onChange={(e) => { set_dateEnd(e.target.value) }}>Дата создания по </InputDateTime>,
        <MultiSelect options={paymentTypes} className="input_wf option-border-none" value={_paymentTypeIds} onChange={handlePaymentStatusChange}>Тип оплаты</MultiSelect>,
        <MultiSelect options={promocodes} className="input_wf options-w_150-r" value={_promocodeIds} onChange={handlePromocodesChange}>Промокоды</MultiSelect>,
        <MultiSelect options={products} className="input_wf  options-w_150-r" value={_productIds} onChange={handleProductsChange}>Продукты</MultiSelect>,
        <MultiSelect options={productCategories} className="input_wf options-w_150-r" value={_productCategoryIds} onChange={handleProductCategoriesChange}>Категории</MultiSelect>,
    ]

    return (
        <>
            <Filters filters={filters}></Filters>
            <div className="analytics__booking">
                <div className="analytics__booking_total">
                    <h3>Всего</h3>
                    <div className="analytics__booking_total-bookings">
                        <div className="analytics__booking_total-bookings-label">
                            Бронирований:
                        </div>
                        <div className="analytics__booking_total-bookings-value">
                            <div>{_totalInfo?.sum_and_count?.count}</div>
                        </div>
                    </div>
                    <div className="analytics__booking_total-sum">
                        <div className="analytics__booking_total-sum-label">Минут:</div>
                        <div className="analytics__booking_total-sum-value">{_totalInfo?.sum_and_count?.minutes}</div>
                    </div>
                </div>
                <div className="analytics__booking_total">
                    <h3>Перворазных</h3>
                    <div className="analytics__booking_total-bookings">
                        <div className="analytics__booking_total-bookings-label">
                            Бронирований:
                        </div>
                        <div className="analytics__booking_total-bookings-value">
                            <div>{_totalInfo?.first_time_bookings?.count}</div>
                        </div>
                    </div>
                    <div className="analytics__booking_total-sum">
                        <div className="analytics__booking_total-sum-label">Минут:</div>
                        <div className="analytics__booking_total-sum-value">{_totalInfo?.first_time_bookings?.minutes}</div>
                    </div>
                </div>
                <div className="analytics__booking_total">
                    <h3>Спорт</h3>
                    <div className="analytics__booking_total-bookings">
                        <div className="analytics__booking_total-bookings-label">
                            Бронирований:
                        </div>
                        <div className="analytics__booking_total-bookings-value">
                            <div>{_totalInfo?.sport_time_bookings?.count}</div>
                        </div>
                    </div>
                    <div className="analytics__booking_total-sum">
                        <div className="analytics__booking_total-sum-label">Минут:</div>
                        <div className="analytics__booking_total-sum-value">{_totalInfo?.sport_time_bookings?.minutes}</div>
                    </div>
                </div>
                <div className="analytics__booking_total">
                    <h3>По заказам с промокодом</h3>
                    <div className="analytics__booking_total-bookings">
                        <div className="analytics__booking_total-bookings-label">
                            Бронирований:
                        </div>
                        <div className="analytics__booking_total-bookings-value">
                            <div>{_totalInfo?.minutes_promo?.count}</div>
                        </div>
                    </div>
                    <div className="analytics__booking_total-sum">
                        <div className="analytics__booking_total-sum-label">Минут:</div>
                        <div className="analytics__booking_total-sum-value">{_totalInfo?.minutes_promo?.minutes}</div>
                    </div>
                </div>
            </div>
            <UniversalChart options={linaeChartOptions} data={prepareLineChartData(_lineChart || {})}></UniversalChart>
        </>
    );
}