//реакт
import React, { useState, useLayoutEffect, useMemo, useRef } from 'react';
//Quill
import ReactQuill, { Quill } from 'react-quill';
//Стили
import './quillStyles.css';
import './contentStyles.css';
//Кастомные форматы Quill
import BoldBlot from './formats/bold';
import ColorClass from './formats/color';
import SizeClass from './formats/fontSize';
import AlignClass from './formats/align';
import IndentClass from './formats/indent';
import Link from './formats/link';
import Image from './formats/image';
import CustomFormatClass from './formats/customFormat';

//компоненты
import Label from "../inputs/label";
import MessageError from "../message/__errors";


export default function ContentEditor(
  {value = null, defaultContent = null, config = 'default', children, 
  maxLength = 2000,  placeholder, errors, className = '', content = null, onChange, readOnly, ...other}
){
  const [editorContent, setEditorContent] = useState('');
  const quillRef = useRef(null);
  const [_warning, setWarning]= useState(null); 

  useLayoutEffect(() => {
    let length = editorContent.length ? editorContent.length : 0;
    if(length < maxLength){
      setWarning(null);
    }else{
      setWarning('Превышен лимит контента!');
    }
  }, [editorContent]); 

  const modulesPresets = {
    defaultPreset: {
      toolbar: {
        container: [
          [{ 'header': [1, 2, 3, 4, 5, false] }],
          ['bold', 'italic', 'underline','strike'],
          [{'color': ColorClass.whitelist}],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          [{'customAlign': AlignClass.whitelist}],
          ['link', {'customFormat': CustomFormatClass.whitelist}],
          //['image'],
          ['clean'],
          [{ 'font': [] }, { 'size':  SizeClass.whitelist}],
          ['copyHTML'], ['pasteHTML']
        ],
        handlers: {
          'copyHTML': async () => {
              try{
                const html = quillRef.current.editor.root.innerHTML;
                if(navigator.clipboard){
                  await navigator.clipboard.writeText(html);
                }else{
                  const textarea = document.createElement('textarea');
                  textarea.value = html;
                  document.body.appendChild(textarea);
                  textarea.select();
                  document.execCommand('copy');
                  document.body.removeChild(textarea);
                }
              }
              catch(err){
                console.error(err);
              }
            },
            'pasteHTML': async () => {
              try{
                if(navigator.clipboard){
                  const text = await navigator.clipboard.readText();
                  quillRef.current.editor.clipboard.dangerouslyPasteHTML(text);
                }else{
                  const text = window.clipboardData.getData('Text');
                  quillRef.current.editor.clipboard.dangerouslyPasteHTML(text);
                }
              }
              catch(err){
                console.error(err);
              }
            }
        }
      }
    },
  
    minimalPreset: {
        toolbar: {
          container: [
            [{ 'header': [1, 2, 3, 4, 5, false] }],
            ['bold', 'italic', 'underline','strike'],
            [{ 'font': [] }],
            ['clean'],
            ['copyHTML'], ['pasteHTML']
          ],
          handlers: {
            'copyHTML': async () => {
              try{
                const html = quillRef.current.editor.root.innerHTML;
                if(navigator.clipboard){
                  await navigator.clipboard.writeText(html);
                }else{
                  const textarea = document.createElement('textarea');
                  textarea.value = html;
                  document.body.appendChild(textarea);
                  textarea.select();
                  document.execCommand('copy');
                  document.body.removeChild(textarea);
                }
              }
              catch(err){
                console.error(err);
              }
            },
            'pasteHTML': async () => {
              try{
                if(navigator.clipboard){
                  const text = await navigator.clipboard.readText();
                  quillRef.current.editor.clipboard.dangerouslyPasteHTML(text);
                }else{
                  const text = window.clipboardData.getData('Text');
                  quillRef.current.editor.clipboard.dangerouslyPasteHTML(text);
                }
              }
              catch(err){
                console.error(err);
              }
            }
          }
        }
    },
  };
  const formatsPresets = {
    defaultPreset: [
      'header',
      'bold', 'italic', 'underline', 'strike',
      'color',
      'list', 'bullet', 'indent',
      // 'image',
      'customAlign',
      'link', 'customFormat',
      'font', 'size'
    ],
    minimalPreset: [
      'header',
      'bold', 'italic', 'underline', 'strike',
      'color',
      'font', 'size'
    ]
  }


  const handleChange = (editorContent, delta, oldDelta, source) => {
    setEditorContent(editorContent);
    // Проверяем, если контент пустой
    if (editorContent !== '<p><br></p>') {
        onChange({ target: { value: editorContent } });
    } else {
        onChange({ target: { value: null } });
    }

    // Проверяем, что изменение произошло из-за вставки
    if (delta.ops.some(op => op.insert  && op.insert.length > 1)) {
        const lastInsertOp = delta.ops[delta.ops.length - 1];
        if(lastInsertOp.insert && typeof lastInsertOp.insert === 'string'){
          checkLink(lastInsertOp.insert);
        }
    }
};

  const checkLink = (lastInsertedText) => {
    if (!/^https?:\/\//i.test(lastInsertedText)) {
        return; 
    }

    const editor = quillRef.current.getEditor();
    const text = editor.getText();
    const linkRegex = new RegExp(lastInsertedText, 'g');

    let match;
    while ((match = linkRegex.exec(text)) !== null) {
        const startIndex = match.index;
        const endIndex = startIndex + lastInsertedText.length;
        const range = { index: startIndex, length: lastInsertedText.length };

        const formats = editor.getFormat(range.index, range.length);
        if(!formats.link){
          editor.formatText(range.index, range.length, { link: lastInsertedText });
        }
    }
  };

  useLayoutEffect(() => {
    if(defaultContent) {
      setEditorContent(defaultContent);
    }else{
      setEditorContent('');
    }
  }, [defaultContent]);

  function setConfigPreset(config){
    switch(config){
      case('minimal'): return modulesPresets.minimalPreset;
    default: return modulesPresets.defaultPreset;
    }
  }
  function setFormatsPreset(config){
    switch(config){
      case('minimal'): return formatsPresets.minimalPreset;
    default: return formatsPresets.defaultPreset;
    }
  }
  
  const modules = useMemo(() => (setConfigPreset(config)), []);
  const formats = useMemo(() => (setFormatsPreset(config)), []);

  return <>
      <Label>{children}</Label>
      <div className={`editor-wrapper ${className}`} readOnly={readOnly ?? false}> 
        <ReactQuill 
          ref={quillRef}
          readOnly={readOnly}
          value={editorContent} 
          onChange={handleChange}
          modules={modules}
          formats={formats}
        />
        <Label className="label__content-length" readOnly={readOnly ?? false}>
          {editorContent.length ? editorContent.length
            + '/' + maxLength : '0/' + maxLength}
        </Label>
        <Label className="label__warning" readOnly={readOnly ?? false}>{_warning}</Label>
      </div>
      {errors ? <MessageError errors={errors} sx={{width: "100%"}}/> : null}
  </>
}