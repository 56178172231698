//Реакт
import React from "react";
//Стили
import "./styles.css";

export default function ShowWrap({children, className = "", ...other}){
  return <div 
    className={`pages__show-wrap ${className}`}
    {...other}
  >
    {children}
  </div>
}