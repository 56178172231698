//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
//Библиотеки и Api
import ApiPlatform from "../../../../../app/api/authorized/admin/event/ApiPlatform";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import InputTextArea from "../../../../components/inputs/textarea/textarea";
//Стили
import './styles.css';

export default function PlatformShow({ ...other}){
  return <Page ApiModel={ApiPlatform} { ...other}>
    <PlatformShowContent { ...other}/>
  </Page>
}

function PlatformShowContent({ ...other}){
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState(null);
  const [_name, set_name] = useState('');
  const [_description, set_description] = useState('');
  const [_created, set_created] = useState(null);
  const [_updated, set_updated] = useState(null);

  contextContent._dataToServer.current = {
    name: _name,
    description: _description,
  };
  useLayoutEffect(()=>{
    contextContent._handleSetData.current = (data) =>{
      set_id(data.id);
      set_name(data.name);
      set_description(data.description);
      set_created(data.created_at);
      set_updated(data.updated_at);
      contextPage.setTitle(`Платформа:  ${data.name}`);
      contextContent.setTitle(`Платформа:  ${data.name}`);
      contextPage.setName(data.name);
    };
    contextContent.doLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return <>
    {!contextContent.isCreate ?
      <InputWrapper>
        <InputText className="pages__show-input" value={_id} readOnly={true}>Номер</InputText>
      </InputWrapper> 
    : null }
    <InputWrapper>
      <InputText className="pages__show-input" value={_name} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_name(e.target.value)}>Платформа</InputText>
    </InputWrapper>
    <InputWrapper>
      <InputTextArea className="pages__show-input" value={_description} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_description(e.target.value)}>Описание</InputTextArea>
    </InputWrapper>
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
      </InputWrapper> 
    : null }
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
      </InputWrapper> 
    : null }
  </>
}
