//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiClientSurvey from "../../../../../app/api/authorized/admin/management/ApiClientSurvey";
import ViewHandler from "../../../../../inc/ViewHandler";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import InputDate from "../../../../components/inputs/date";
import InputEmail from "../../../../components/inputs/email/email";
import Select from "../../../../components/inputs/select";
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
//Material UI
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import BadgeIcon from '@mui/icons-material/Badge';
import NumbersIcon from '@mui/icons-material/Numbers';
import FemaleIcon from '@mui/icons-material/Female';
import CakeIcon from '@mui/icons-material/Cake';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BorderColorIcon from '@mui/icons-material/BorderColor';
//Стили
import './styles.css';

export default function ClientIndex({ ...other }) {
    return <Page {...other}>
        <ClientIndexContent {...other} />
    </Page>
}

function ClientIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_client_fullname, set_client_fullname] = useState('');
    const [_client_email, set_client_email] = useState('');
    const [_client_phone, set_client_phone] = useState('');
    const [_gender, set_gender] = useState('');
    const [_birthday, set_birthday] = useState('');
    const [_survey_name, set_survey_name] = useState('');
    const [_signature, set_signature] = useState('');
    const [_date_of_signature, set_date_of_signature] = useState('');
    const [_survey_type, set_survey_type] = useState(null);
    const [_created_by_user, set_created_by_user] = useState('');
    const [_updated_by_user, set_updated_by_user] = useState('');
    
    const surveyTypes = useSelector(state => state.adminOption.surveys);
    
    useLayoutEffect(() => {
        contextPage.setTitle(`Анкеты`);
        contextContent.setTitle(`Анкеты`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Номер</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="client_fullname"
            name="client_fullname"
            isSort={false}
        >
            <div className="header-row__center">
                <BadgeIcon/>
                <div className="header-row__center_text">Клиент</div> 
            </div>
        </ListHeaderItem>,
         <ListHeaderItem
            key="client_email"
            name="client_email"
            isSort={false}
        >
            <div className="header-row__center">
                <EmailIcon/>
                <div className="header-row__center_text">Почта</div> 
            </div>
         </ListHeaderItem>,
        <ListHeaderItem
            key="client_phone"
            name="client_phone"
            isSort={false}
        >
            <div className="header-row__center">
                <PhoneIcon/>
                <div className="header-row__center_text">Телефон</div> 
            </div>
       </ListHeaderItem>,
        <ListHeaderItem
            key="gender"
            name="gender"
            isSort={false}
        >
            <div className="header-row__center">
                <FemaleIcon/>
                <div className="header-row__center_text">Пол</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="birthday"
            name="birthday"
            isSort={false}
        >
            <div className="header-row__center">
                <CakeIcon/>
                <div className="header-row__center_text">Дата рождения</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="survey_name"
            name="survey_name"
            isSort={false}
        >
            <div className="header-row__center">
                <ListAltIcon/>
                <div className="header-row__center_text">Анкета</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="is_signature"
            name="is_signature"
        >
            <div className="header-row__center">
                <BorderColorIcon/>
                <div className="header-row__center_text">Подпись</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="dateOfSignature"
            name="dateOfSignature"
            isSort={false}
        >
            <div className="header-row__center">
                <EditCalendarIcon/>
                <div className="header-row__center_text">Дата заполнения</div> 
            </div>
        </ListHeaderItem>,
        // <ListHeaderItem
        //     key="created_by_user"
        //     name="created_by_user"
        //     isSort={false}
        // >
        //     Кем создана
        // </ListHeaderItem>,
        // <ListHeaderItem
        //     key="updated_by_user"
        //     name="updated_by_user"
        //     isSort={false}
        // >
        //     Кем обновлена
        // </ListHeaderItem>,
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
    ];

    const handleSurveyTypeChange = (event) => {
        set_survey_type(event.target.value);
      } 

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>Номер</InputNumber>,
        <InputText className="input_wf" value={_client_fullname} onChange={(e) => { set_client_fullname(e.target.value) }}>ФИО клиента</InputText>,
        <InputEmail className="input_wf" value={_client_email} onChange={(e) => { set_client_email(e.target.value) }}>Почта</InputEmail>,
        <InputNumber className="input_wf" value={_client_phone} onChange={(e) => { set_client_phone(e.target.value) }}>Телефон</InputNumber>,
        <InputDate className="input_wf" value={_birthday} onChange={(e) => { set_birthday(e.target.value) }}>Дата рождения</InputDate>,
        <Select className="input_wf" options={surveyTypes} value={_survey_type} onChange={handleSurveyTypeChange}>Анкета</Select>,
        <InputDate className="input_wf" value={_date_of_signature} onChange={(e) => { set_date_of_signature(e.target.value) }}>Дата заполнения</InputDate>,
        // <InputText className="input_wf" value={_created_by_user} onChange={(e) => { set_created_by_user(e.target.value) }}>Кем создана</InputText>,
        // <InputText className="input_wf" value={_updated_by_user} onChange={(e) => { set_updated_by_user(e.target.value) }}>Кем собновлена</InputText>
    ];
    
    
    const renderRow = (row, i) => {
        return [
            <ListBodyItem key="id" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
            <ListBodyItem key="client_fullname" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>{row.client_fullname}</ListBodyItem>,
            <ListBodyItem key="client_email" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{row.client_email}</ListBodyItem>,
            <ListBodyItem key="client_phone" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>{row.client_phone}</ListBodyItem>,
            <ListBodyItem key="gender" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>{row.gender}</ListBodyItem>,
            <ListBodyItem key="birthday" data-label={ViewHandler.getComponentTextByClass(header[5].props.children, 'header-row__center_text')}>{row.birthday}</ListBodyItem>,
            <ListBodyItem key="survey_name" data-label={ViewHandler.getComponentTextByClass(header[6].props.children, 'header-row__center_text')}>{row.survey_name}</ListBodyItem>,
            <ListBodyItem key="is_signature" data-label={ViewHandler.getComponentTextByClass(header[7].props.children, 'header-row__center_text')}>
                {
                    row.is_signature ? <div className="is-active_icon--success"><VerifiedIcon/></div>
                    : <div className="is-active_icon--danger"><NewReleasesIcon/></div> 
                }
            </ListBodyItem>,
            <ListBodyItem key="date_of_signature" data-label={ViewHandler.getComponentTextByClass(header[8].props.children, 'header-row__center_text')}>{row.date_of_signature}</ListBodyItem>,
            // <ListBodyItem key="created_by_user" data-label={header[8].props.children}>{row.created_by_user}</ListBodyItem>,
            // <ListBodyItem key="updated_by_user" data-label={header[9].props.children}>{row.updated_by_user}</ListBodyItem>,    
            <ListBodyItem key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiClientSurvey}
            renderRow={renderRow}
            searchParams={{ id: _id, client_fullname: _client_fullname, client_email: _client_email, client_phone: _client_phone, birthday: _birthday, surveyName: _survey_type, dateOfSignature: _date_of_signature, created_by_user: _created_by_user, updated_by_user: _updated_by_user}}
        />
    </>
}