//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiOrderBookings from "../../../../../app/api/authorized/admin/order/ApiOrderBookings";
import { route } from "../../../../../inc/Routes";
import ViewHandler from "../../../../../inc/ViewHandler";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
import InputPhone from "../../../../components/inputs/phone/phone";
import MultiSelect from "../../../../components/inputs/milti-select";
//Mui icons
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import NumbersIcon from '@mui/icons-material/Numbers';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import ContactlessIcon from '@mui/icons-material/Contactless';
//Стили
import './styles.css';

export default function OrderBookingIndex({ ...other }) {
    return <Page {...other}>
        <OrderBookingIndexContent {...other} />
    </Page>
}

function OrderBookingIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_name, set_name] = useState('');
    const [_phone, set_phone] = useState('');
    const [_email, set_email] = useState('');
    const [_bookingCode, set_bookingCode] = useState('');
    const [_promocodeName, set_promocodeName] = useState('');
    const [_productIds, set_productIds] = useState([]);
    const [_price, set_price] = useState(null);
    const [_payType, set_payType] = useState([])

    const products = useSelector(state => state.adminOption.products);
    const payTypes = useSelector(state => state.commonOption.paymentTypes);

    const handleProductsChange = (event) => {
        set_productIds(event.target.value);
    };

    const handlePayTypeChange = (event) => {
        set_payType(event.target.value)
    }

    useLayoutEffect(() => {
        contextPage.setTitle(`Полетное время`);
        contextContent.setTitle(`Полетное время`);
    }, [contextContent, contextPage]);

    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Заказ</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="bookingCode"
            name="bookingCode"
        >
            <div className="header-row__center">
                <AirplaneTicketOutlinedIcon/>
                <div className="header-row__center_text">Бронь</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="name"
            name="name"
        >
            <div className="header-row__center">
                <PersonIcon/>
                <div className="header-row__center_text">Пользователь</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="phone"
            name="phone"
        >
            <div className="header-row__center">
                <PhoneIcon/>
                <div className="header-row__center_text">Телефон</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="email"
            name="email"
        >
            <div className="header-row__center">
                <EmailIcon/>
                <div className="header-row__center_text">Email</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
           key="price"
           name="price"
        >
            <div className="header-row__center">
                <CurrencyRubleIcon/>
                <div className="header-row__center_text">Итого</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="product"
            name="product"
            isSort={false}
        >
            <div className="header-row__center">
                <InterestsOutlinedIcon/>
                <div className="header-row__center_text">Продукт</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="promocode"
            name="promocode"
        >
            <div className="header-row__center">
                <QrCode2OutlinedIcon/>
                <div className="header-row__center_text">Промокод</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="status"
            name="status"
        >
            <div className="header-row__center">
                <FmdBadIcon/>
                <div className="header-row__center_text">Статус</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="payment_type_id"
            name="payment_type_id"
        >
            <div className="header-row__center">
                <ContactlessIcon/>
                <div className="header-row__center_text">Тип оплаты</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
            className="create_mobile"
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>Номер</InputNumber>,
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Пользователь</InputText>,
        <InputPhone className="input_wf" useReg={false} value={_phone} onChange={(e) => { set_phone(e.target.value) }}>Телефон</InputPhone>,
        <InputText className="input_wf" value={_email} onChange={(e) => { set_email(e.target.value) }}>Email</InputText>,
        <InputText className="input_wf" value={_bookingCode} onChange={(e) => { set_bookingCode(e.target.value) }}>Код</InputText>,
        <MultiSelect className="input_wf options-w_150-r" options={products} value={_productIds} onChange={handleProductsChange}>Продукты</MultiSelect>,
        <InputText className="input_wf" value={_promocodeName} onChange={(e) => { set_promocodeName(e.target.value) }}>Промокод</InputText>,
        <MultiSelect className="input_wf options-w_150-r" options={payTypes} value={_payType} onChange={handlePayTypeChange}>Тип оплаты</MultiSelect>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-bookings_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="bookingCode" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>
                <a href={route(`authorized.admin.booking.bookings.show`,{id: row.bookingCode})}>{row.bookingCode}</a>
            </ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="name" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}><a href={route(`authorized.admin.user.users.show`,{id: row.booking_user_id})}>{row.name}</a></ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="phone" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>{row.phone}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="email" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>{row.email}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="price" data-label={ViewHandler.getComponentTextByClass(header[5].props.children, 'header-row__center_text')}>{row.total} ₽</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="product" data-label={ViewHandler.getComponentTextByClass(header[6].props.children, 'header-row__center_text')}>
                {row.products.map((product) => (
                <div key={product.id}>
                    <div>Время: {product.minutes}</div>
                    <div>Цена: {product.price} ₽ ({product.total} ₽)</div>
                </div>
                ))}
            </ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="promocode" data-label={ViewHandler.getComponentTextByClass(header[7].props.children, 'header-row__center_text')}>{row.promocode_name}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="status" data-label={ViewHandler.getComponentTextByClass(header[8].props.children, 'header-row__center_text')}>{row.status}</ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="payment_type_id" data-label={ViewHandler.getComponentTextByClass(header[9].props.children, 'header-row__center_text')}>{row.payment_type_name}</ListBodyItem>,
            // <ListBodyItem className="admin-bookings_column-width" key="created_at" data-label={header[9].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
            // <ListBodyItem className="admin-bookings_column-width" key="updated_at" data-label={header[10].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiOrderBookings}
            renderRow={renderRow}
            searchParams={
                { 
                    id: _id, 
                    name: _name, 
                    phone: _phone, 
                    email: _email, 
                    bookingCode: _bookingCode, 
                    price: _price, 
                    products: _productIds, 
                    promocode: _promocodeName,
                    paymentTypeIds: _payType,
                }
            }
        />
    </>
}
