import React, { useContext, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
//Модули Content
import { ContextApp } from "../../modules/app/appContext";
//Библиотеки
import { route } from "../../../inc/Routes";
//Компоненты
import MenuItem from "./__item";
import MenuItems from "./menu-items";
//Api
import ApiAuth from "../../../app/api/public/system/ApiAuth";
//Material UI
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import CurrencyRubleOutlinedIcon from '@mui/icons-material/CurrencyRubleOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ParaglidingOutlinedIcon from '@mui/icons-material/ParaglidingOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import SportsMotorsportsOutlinedIcon from '@mui/icons-material/SportsMotorsportsOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import ScubaDivingOutlinedIcon from '@mui/icons-material/ScubaDivingOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined';
import MoreTimeOutlinedIcon from '@mui/icons-material/MoreTimeOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import BlurLinearOutlinedIcon from '@mui/icons-material/BlurLinearOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import PeopleIcon from '@mui/icons-material/People';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

// Сиситемные иконки
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

//Стили
import "./styles.css";
import styles from "./style";

export default function Menu({ children, isOpen = false, openMenu, closeMenu, ...other }) {
    const { checkPermission } = useContext(ContextApp);
    const [searchText, setSearchText] = useState("");
    const location = useLocation();
    const inputRef = useRef(null);
    const menuRef = useRef(null); // Ссылка на контейнер меню

    const groups = [
        { groupName: "Управление клиентами", slug: "Management", icon: <SupervisedUserCircleOutlinedIcon/>},
        { groupName: "Заказы", slug: "Orders", icon: <CurrencyRubleOutlinedIcon />},
        { groupName: "Контент", slug: "Content", icon: <DashboardCustomizeOutlinedIcon />},
        { groupName: "Бронирование", slug: "Booking", icon: <CollectionsBookmarkOutlinedIcon />},
        { groupName: "Мероприятия", slug: "Events", icon: <ScubaDivingOutlinedIcon />},
        { groupName: "Статистика", slug: "Statistics", icon: <DataSaverOnOutlinedIcon />},
        { groupName: "Управление пользователями", slug: "users", icon: <ManageAccountsIcon/>},
    ]

    const links = [
        //Менеджмент
        { permission: 'admin.users.index', category_slug: "Management", elem: { label: 'Клиенты', icon: PeopleIcon, path: route("authorized.admin.management.clients.index") } },
        //Заказы
        { permission: 'admin.order.certificates.index', category_slug: "Orders", elem: { label: 'Сертификаты', icon: ReceiptLongOutlinedIcon, path: route("authorized.admin.order.certificates.index") } },
        { permission: 'admin.order.bookings.index', category_slug: "Orders", elem: { label: 'Полетное время  ', icon: ParaglidingOutlinedIcon, path: route("authorized.admin.order.bookings.index") } },
        { permission: 'admin.order.balances.index', category_slug: "Orders", elem: { label: 'Депозиты', icon: UpdateOutlinedIcon, path: route("authorized.admin.order.balances.index") } },
        { permission: 'admin.order.competitions.index', category_slug: "Orders", elem: { label: 'Соревнования', icon: SportsMotorsportsOutlinedIcon, path: route("authorized.admin.order.competitions.index") } },
        { permission: 'admin.order.amo.index', category_slug: "Orders", elem: { label: 'AmoCrm', icon: BlurLinearOutlinedIcon, path: route("authorized.admin.order.amo.index") } },
        //Контент
        { permission: 'admin.products.index', category_slug: "Content", elem: { label: 'Продукты', icon: InterestsOutlinedIcon, path: route("authorized.admin.content.products.index") } },
        { permission: 'admin.categories.index', category_slug: "Content", elem:{ label: 'Категории', icon: CategoryOutlinedIcon, path: route("authorized.admin.content.categories.index") } },
        { permission: 'admin.promocodes.index',  category_slug: "Content", elem: { label: 'Промокоды', icon: QrCode2OutlinedIcon, path: route("authorized.admin.content.promocodes.index") } },
        { permission: 'admin.promotions.index', category_slug: "Content", elem: { label: 'Акции', icon: DiscountOutlinedIcon, path: route("authorized.admin.content.promotions.index") } },
        { permission: 'admin.news.index', category_slug: "Content", elem: { label: 'Новости', icon: NewspaperOutlinedIcon, path: route("authorized.admin.content.news.index") } },
        //Менеджмент
        { permission: 'admin.management.surveys.index', category_slug: "Management", elem: { label: 'Анкеты', icon: ArticleOutlinedIcon, path: route("authorized.admin.management.surveys.index") } },
        //Бронирование
        { permission: 'admin.booking.balance.index', category_slug: "Booking", elem: { label: 'Депозиты', icon: MoreTimeOutlinedIcon, path: route("authorized.admin.booking.balances.index") } },
        { permission: 'admin.booking.balance-difference.index', category_slug: "Booking",  elem: { label: 'Депозиты (разница)', icon: DifferenceOutlinedIcon, path: route("authorized.admin.booking.balance-diff.index") } },
        { permission: 'admin.booking.times.index', category_slug: "Booking",  elem: { label: 'Транзакции', icon: PublishedWithChangesOutlinedIcon, path: route("authorized.admin.booking.times.index") } },
        { permission: 'admin.booking.bookings.index', category_slug: "Booking",  elem: { label: 'Бронирования', icon: AirplaneTicketOutlinedIcon, path: route("authorized.admin.booking.bookings.index") } },
        //Мероприятия
        { permission: 'admin.platforms.index', category_slug: "Events", elem: { label: 'Площадки', icon: RoomOutlinedIcon, path: route("authorized.admin.event.platforms.index") } },
        { permission: 'admin.banners.index', category_slug: "Events", elem: { label: 'Банеры', icon: PanoramaOutlinedIcon, path: route("authorized.admin.event.banners.index") } },
        { permission: 'admin.calendars.index', category_slug: "Events", elem: { label: 'Календари бронирований', icon: EditCalendarOutlinedIcon, path: route("authorized.admin.event.calendar.calendars.index") } },
        { permission: 'admin.calendars.index', category_slug: "Events", elem: { label: 'Календари категорий', icon: DateRangeOutlinedIcon, path: route("authorized.admin.event.calendar.categories.index") } },
        //Статистика
        // { permission: 'admin.statistic.analytics.index', category_slug: "Statistics", elem: { label: 'Аналитика (бронирования)', icon: MultilineChartIcon, path: route("authorized.admin.statistic.analytics.booking.index") } },
        { permission: 'admin.statistic.analytics.index', category_slug: "Statistics", elem: { label: 'Аналитика (заказы)', icon: QueryStatsOutlinedIcon, path: route("authorized.admin.statistic.analytics.order.index") } },
        { permission: 'admin.statistic.parameters.index', category_slug: "Statistics", elem: { label: 'Показатели', icon: BarChartOutlinedIcon, path: route("authorized.admin.statistic.parameters.index") } },
        //Users
        { permission: 'admin.users.index', category_slug: "users", elem: { label: 'Пользователи', icon: AssignmentIndOutlinedIcon, path: route("authorized.admin.user.users.index") } },
        { permission: 'admin.roles.index', category_slug: "users", elem: { label: 'Роли', icon: BadgeOutlinedIcon, path: route("authorized.admin.user.roles.index") } },
        //Без группы
        { permission: null, category_slug: null, elem: { label: 'Магазин', icon: ShoppingCartOutlinedIcon, path: route("authorized.account.home.index") } },
        { permission: 'account.order.certificates.index', category_slug: null, elem: { label: 'Сертификаты', icon: ReceiptLongOutlinedIcon, path: route("authorized.account.order.certificates.index") } },
        { permission: 'account.booking.bookings.index', category_slug: null, elem: { label: 'Бронирования', icon: AirplaneTicketOutlinedIcon, path: route("authorized.account.booking.bookings.index") } },
        { permission: 'account.booking.balances.index', category_slug: null, elem: { label: 'Депозиты', icon: UpdateOutlinedIcon, path: route("authorized.account.booking.balances.index") } },
        { permission: 'common.profile.show', category_slug: null, elem: { label: 'Профиль', icon: AccountCircleOutlinedIcon, path: route("authorized.common.profile.show") } },
    ]

    const getMenuItems = (items) => {
        let availableItems = [];
        items.forEach((item) => {
            if (checkPermission(item.permission)) availableItems.push(item.elem);
        });
        return availableItems;
    };

    const handleMouseEnter = () => {
        if (!isOpen) openMenu();
    };

    const handleMouseLeave = () => {
        closeMenu();
    };

    const showSpecificLink = (link) => {
        if (searchText) return true;
        else {
            return link.category_slug == null && (checkPermission(link.permission) || link.permission == null)
        }
    }

    const handleSearchClick = () => {
        if(!isOpen) {
            openMenu()
       
            setTimeout(() => {
                if (inputRef.current) { inputRef.current.focus(); }
            }, 0);
        }
    }

    const handleMenuItemClick = () => {
        // Сохраняем текущую позицию прокрутки
        const scrollPosition = menuRef.current.scrollTop;

        // Сохраняем позицию в локальном хранилище
        localStorage.setItem('menuScrollPosition', scrollPosition);
    };

    useEffect(() => {
        // При загрузке компонента, восстанавливаем позицию прокрутки
        const savedScrollPosition = localStorage.getItem('menuScrollPosition');
        if (menuRef.current && savedScrollPosition) {
            menuRef.current.scrollTop = savedScrollPosition;
        }
    }, [location.pathname]);

    return (
        <div
            {...other}
            className={`menu ${isOpen ? 'is-open' : ''}`}
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            onClick={(e) => e.stopPropagation()} // предотвращает всплытие кликов до document
        >
            <div className={`menu__wrapper ${isOpen ? 'is-open' : ''}`} ref={menuRef}>
                <div className="menu__header">
                    <div className="menu__header_burger">
                        <IconButton
                            onClick={isOpen ? closeMenu : openMenu}
                            sx={styles.iconMain}
                            size="large"
                        >
                            <MenuIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                    {checkPermission("admin.booking.manifest.index") ?
                        <MenuItem
                            isOpen={isOpen}
                            closeMenu={closeMenu}
                            Icon={DashboardCustomizeIcon}
                            onClick={() => {
                                window.open("https://booking.flystation.net/");
                            }}
                        >Система бронирования</MenuItem>
                    : null}
                    <MenuItem
                        isOpen={isOpen}
                        Icon={SearchIcon}
                        onClick={handleSearchClick}
                        className="menu__item--no-hover menu__item_search"
                    >
                        <input ref={inputRef} placeholder="Поиск ..." className="menu__header_search" value={searchText} onInput={(e) => {setSearchText(e.target.value)}}/>
                    </MenuItem>
                </div>
                {
                    searchText.length == 0 ?
                    <div className="menu__body">
                        {
                            groups.map((group) => {
                                return (<MenuItems
                                    key={group.slug}
                                    isOpen={isOpen}
                                    closeMenu={closeMenu}
                                    groupName={group.groupName}
                                    slug={group.slug}
                                    icon={group.icon}
                                    onItemClick={() => handleMenuItemClick()}
                                    menuItems={getMenuItems(
                                        links.filter(link => link.category_slug === group.slug)
                                    )}
                                />)
                            })
                        }
                    </div>
                    : null
                }  
                {
                    links.filter(link => link.elem.label.toLowerCase().startsWith(searchText.toLowerCase())).map((link) =>  {
                        return ( showSpecificLink(link) ?
                        <div key={link?.elem?.label} className="menu__body">
                            <MenuItem
                                className={` ${ link.category_slug ? '' : 'menu__item--outlined'}`}
                                selectedClassName={"menu__item--outlined-selected"}
                                isOpen={isOpen}
                                closeMenu={closeMenu}
                                Icon={link?.elem?.icon}
                                path={link?.elem?.path}
                                active={location.pathname.startsWith(link?.elem?.path)}
                                onClick={() => handleMenuItemClick()}
                            >{link?.elem?.label}</MenuItem>
                        </div> : null 
                        )
                    })
                }
                <div className="menu__footer">
                    <MenuItem
                        isOpen={isOpen}
                        closeMenu={closeMenu}
                        Icon={ExitToAppIcon}
                        onClick={async () => { await ApiAuth.Logout(); }}
                    >Выход</MenuItem>
                </div>
            </div>
        </div>
    );
}
