//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiProduct from "../../../../../app/api/authorized/admin/content/ApiProduct";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number"
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import InputTextArea from "../../../../components/inputs/textarea/textarea";
import InputCheckbox from "../../../../components/inputs/checkbox/checkbox";
import Select from "../../../../components/inputs/select";
import ShowWrap from "../../../../components/wrappers/show-wrap";
import ShowWrapItem from "../../../../components/wrappers/show-wrap-item";
//Стили
import './styles.css';

export default function ProductShow({ ...other}){
  return <Page className="content-overflow-visible" ApiModel={ApiProduct} { ...other}>
    <ProductShowContent { ...other}/>
  </Page>
}

function ProductShowContent({ ...other}){
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState(null);
  const [_name, set_name] = useState('');
  const [_minutes, set_minutes] = useState(null);
  const [_price, set_price] = useState(null);
  const [_oldPrice, set_oldPrice] = useState(null);
  const [_terms, set_terms] = useState('');
  const [_description, set_description] = useState('');
  const [_isActive, set_isActive] = useState(1);
  const [_categoryId, set_categoryId] = useState(null);
  const [_created, set_created] = useState(null);
  const [_updated, set_updated] = useState(null);

  const productCategories = useSelector(state => state.adminOption.productCategories);

  const handleProductCategoryChange = (event) => {
    set_categoryId(event.target.value);
  };

  const handleIsActiveChange = () => {
    set_isActive(Number(!_isActive));
  }

  contextContent._dataToServer.current = {
    name: _name,
    minutes: _minutes,
    price: _price,
    old_price: _oldPrice,
    terms: _terms,
    description: _description,
    is_active: _isActive,
    category_id: _categoryId,
  };
  useLayoutEffect(()=>{
    contextContent._handleSetData.current = (data) =>{
      set_id(data.id);
      set_name(data.name);
      set_minutes(data.minutes);
      set_price(data.price);
      set_terms(data.terms);
      set_oldPrice(data.old_price);
      set_description(data.description);
      set_isActive(data.is_active);
      set_categoryId(data.category_id);
      set_created(data.created_at);
      set_updated(data.updated_at);
      contextPage.setTitle(`Продукт:  ${data.name}`);
      contextContent.setTitle(`Продукт:  ${data.name}`);
      contextPage.setName(data.name);
    };
    contextContent.doLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return <>
  <ShowWrap>
      <ShowWrapItem>
          {!contextContent.isCreate ?
            <InputWrapper>
              <InputText className="pages__show-input" value={_id} readOnly={true}>Номер</InputText>
            </InputWrapper> 
          : null }
          <InputWrapper>
            <InputText className="pages__show-input" value={_name} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_name(e.target.value)}>Продукт</InputText>
          </InputWrapper>
          <InputWrapper>
            <InputNumber className="pages__show-input" value={_minutes} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_minutes(e.target.value)}>Время</InputNumber>
          </InputWrapper>
          <InputWrapper>
            <InputNumber className="pages__show-input" value={_price} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_price(e.target.value)}>Цена</InputNumber>
          </InputWrapper>
          <InputWrapper>
            <InputNumber className="pages__show-input" value={_oldPrice} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_oldPrice(e.target.value)}>Старая цена</InputNumber>
          </InputWrapper>
          <InputWrapper>
            <InputTextArea className="pages__show-input" value={_terms} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_terms(e.target.value)}>Условия</InputTextArea>
          </InputWrapper>
      </ShowWrapItem>
      <ShowWrapItem>
          <InputWrapper>
            <InputTextArea className="pages__show-input" value={_description} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_description(e.target.value)}>Описание</InputTextArea>
          </InputWrapper>
          <InputWrapper>
            <Select className="pages__show-input" options={productCategories} value={_categoryId} readOnly={!contextContent.isCreateOrEdit} onChange={handleProductCategoryChange}>Категория</Select>
          </InputWrapper>
          <InputWrapper>
            <InputCheckbox className="pages__show-input" value={_isActive} readOnly={!contextContent.isCreateOrEdit}  onClick={handleIsActiveChange}>Активация</InputCheckbox>
          </InputWrapper>
          {!contextContent.isCreate ? 
            <InputWrapper>
              <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
            </InputWrapper> 
          : null }
          {!contextContent.isCreate ? 
            <InputWrapper>
              <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
            </InputWrapper> 
          : null }
      </ShowWrapItem>
  </ShowWrap>
  </>
}
