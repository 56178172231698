import React, { useState, useLayoutEffect, useRef } from "react";

//Компоненты
import InputWrapper from "../../input/__wrapper/input__wrapper";
import InputPhone from "../../inputs/phone/phone";
import InputEmail from "../../inputs/email/email";
import InputText from "../../inputs/text/text";
import InputDate from "../../inputs/date/date";
import Label from "../../inputs/label/label";
// Styles
import "./styles.css";

export default function ValueInput({ question, clientAnswer, info, readOnly, onOtherInputChange, onUserFieldChange}) {
  const [value, set_value] = useState('');
  
  const userInfoQuestionSlugs = ['email', 'phone', 'parent-email', 'parent-phone', 'child-name', 'child-surname', 'birthday'];
 
  useLayoutEffect(() => {
    if (clientAnswer) {
      set_value(clientAnswer.value);
      onOtherInputChange(clientAnswer.question_id, clientAnswer.value)
    } 
  }, [clientAnswer]);

  const handleChange = (event) => {
    let questionId = event.target.name;
    let value = event.target.value;
    onOtherInputChange(questionId, value);
    set_value(value);
    handleUserFieldsChange(event);
  }

  const handleUserFieldsChange = (event) => {//отслеживание изменения вопросов с почтой и номером телефона
    let questionSlug = event.target.getAttribute('slug');
    if(userInfoQuestionSlugs.includes(questionSlug)){
      onUserFieldChange(questionSlug, event.target.value)
    }
  }

  function getInput(type){
    if(type == 'text') 
      return <>
        <InputWrapper>
          <InputText 
            className="input pages__show-input" 
            name={question.id} 
            slug={question.slug}
            value={value}
            onChange={handleChange} 
            readOnly={readOnly || question.readonly}
          >{question.name + (question.is_required ? '*' : '')}</InputText>
        </InputWrapper>
        {info.info && (question.slug == info.questionSlug) ?
            <Label className="survey__label-info">{info.info}</Label> 
          : null}
     </>
    if(type == 'email')
      return <>
        <InputWrapper>
          <InputEmail 
            className="input pages__show-input" 
            name={question.id} 
            slug={question.slug}
            value={value}
            onChange={handleChange} 
            readOnly={readOnly}
          >{question.name + (question.is_required ? '*' : '')}</InputEmail>
        </InputWrapper>
        {info.info && (question.slug == info.questionSlug) ?
            <Label className="survey__label-info">{info.info}</Label> 
          : null}
     </>
    if(type == 'phone') 
      return <>
        <InputWrapper>
          <InputPhone 
            className="input pages__show-input" 
            name={question.id} 
            slug={question.slug}
            value={value}
            onChange={handleChange} 
            readOnly={readOnly}
            >{question.name + (question.is_required ? '*' : '')}</InputPhone>
        </InputWrapper>
        {info.info && (question.slug == info.questionSlug) ?
            <Label className="survey__label-info">{info.info}</Label> 
          : null}
      </>
    if(type == 'date')
      return <>
        <InputWrapper>
          <InputDate 
            className="input pages__show-input" 
            name={question.id} 
            slug={question.slug}
            value={value}
            onChange={handleChange} 
            readOnly={readOnly}
            >{question.name + (question.is_required ? '*' : '')}</InputDate>
          </InputWrapper>
          {info.info && (question.slug == info.questionSlug) ?
            <Label className="survey__label-info">{info.info}</Label> 
          : null}
      </>
  }

  return getInput(question.data_type);
}
