//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import ApiOrderCertificates from "../../../../../app/api/authorized/admin/order/ApiOrderCerificates";
import ApiOrderToEmail from "../../../../../app/api/authorized/admin/order/ApiOrderToEmail";
import ApiActivCertificates from "../../../../../app/api/authorized/admin/order/ApiActiveCertificates";
import ApiGenerateCertificate from "../../../../../app/api/authorized/admin/order/ApiGenerateCertificate";
import { route } from "../../../../../inc/Routes";
import ViewHandler from "../../../../../inc/ViewHandler";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
import MyModal from "../../../../components/modals/modal/index"
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import MultiSelect from "../../../../components/inputs/milti-select";
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
import InputPhone from "../../../../components/inputs/phone/phone";
import Button from "../../../../components/button/button";
//Material UI
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import PersonIcon from '@mui/icons-material/Person';
import NumbersIcon from '@mui/icons-material/Numbers';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import ContactlessIcon from '@mui/icons-material/Contactless';
//Стили
import './styles.css';

export default function OrderCertificateIndex({ ...other }) {
    return <Page {...other}>
        <OrderCertificateIndexContent {...other} />
    </Page>
}

function OrderCertificateIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_name, set_name] = useState('');
    const [_phone, set_phone] = useState('');
    const [_email, set_email] = useState('');
    const [_code, set_code] = useState('');
    const [_price, set_price] = useState('');
    const [_productIds, set_productIds] = useState([]);
    const [_promocodeName, set_promocodeName] = useState('');
    const [_isActionLoading, set_isActionLoading] = useState(false);
    const [_sendTo, set_sendTo] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [_sendEmailData, set_sendEmailData] = useState([]);
    const [_payType, set_payType] = useState([])

    const products = useSelector(state => state.adminOption.products);
    const payTypes = useSelector(state => state.commonOption.paymentTypes);

    const setMultiEmailData = (data) => {
        openModal();
        set_sendEmailData(data);
    };

    const sendMultiEmail = () => {
        const fetchEmailSend = async () => {
            // Извлекаем идентификаторы заказов из массива data
            const orders = _sendEmailData.map(item => item.id);
            set_isActionLoading(true);
            // Создаем API вызов с необходимыми параметрами
            let apiEmailSend = ApiOrderToEmail.Store({
                email: _sendTo, // Замените на нужный email
                orders: orders // Передаем массив идентификаторов заказов
            });

            await apiEmailSend.getResponse({});
            set_isActionLoading(false);
            set_sendEmailData([])
            closeModal()
        };

        fetchEmailSend();
    };

    const deactivateMultiCertificate = (data) => {

        const fetchDeactivate = async () => {
            set_isActionLoading(true);
            const orders = data.map(item => item.id);

            let apiActiveSet = ApiActivCertificates.Store({
                active: 0,
                orders: orders
            });

            await apiActiveSet.getResponse({});
            set_isActionLoading(false);
        };

        fetchDeactivate();
    };

    const activateMultiCertificate = (data) => {

        const fetchActivate = async () => {
            set_isActionLoading(true);
            const orders = data.map(item => item.id);

            let apiActiveSet = ApiActivCertificates.Store({
                active: 1,
                orders: orders
            });

            await apiActiveSet.getResponse({});
            set_isActionLoading(false);
        };

        fetchActivate();
    };

    const generateCertificate = (data) => {

        const fetchGenerateCertificate = async () => {
            set_isActionLoading(true);
            const orders = data.map(item => item.id);

            let apiGenerateCode = ApiGenerateCertificate.Store({
                orders: orders
            });

            await apiGenerateCode.getResponse({});
            set_isActionLoading(false);
        };

        fetchGenerateCertificate();
    };

    const handleProductsChange = (event) => {
        set_productIds(event.target.value);
    };

    const handlePayTypeChange = (event) => {
        set_payType(event.target.value)
    }

    const openModal = () => {
        setIsModalOpen(true);
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
    };

    useLayoutEffect(() => {
        contextPage.setTitle(`Сертификаты`);
        contextContent.setTitle(`Сертификаты`);
    }, [contextContent, contextPage]);

    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Заказ</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="bookingCode"
            name="bookingCode"
        >
            <div className="header-row__center">
                <AirplaneTicketOutlinedIcon/>
                <div className="header-row__center_text">Бронь</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="name"
            name="name"
        >
            <div className="header-row__center">
                <PersonIcon/>
                <div className="header-row__center_text">Клиент</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="phone"
            name="phone"
        >
            <div className="header-row__center">
                <PhoneIcon/>
                <div className="header-row__center_text">Телефон</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="email"
            name="email"
        >
            <div className="header-row__center">
                <EmailIcon/>
                <div className="header-row__center_text">Почта</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="code"
            name="code"
        >
            <div className="header-row__center">
                <ReceiptLongOutlinedIcon/>
                <div className="header-row__center_text">Серттификат</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="price"
            name="price"
        >
            <div className="header-row__center">
                <CurrencyRubleIcon/>
                <div className="header-row__center_text">Итого</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="product"
            name="product"
            isSort={false}
        >
            <div className="header-row__center">
                <InterestsOutlinedIcon/>
                <div className="header-row__center_text">Продукт</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="promocode"
            name="promocode"
        >
            <div className="header-row__center">
                <QrCode2OutlinedIcon/>
                <div className="header-row__center_text">Промокод</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="status"
            name="status"
        >
            <div className="header-row__center">
                <FmdBadIcon/>
                <div className="header-row__center_text">Статус</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_end"
            name="date_end"
            isSort={false}
        >
            <div className="header-row__center">
                <TimelapseIcon/>
                <div className="header-row__center_text">Истекает</div> 
            </div>
        </ListHeaderItem>,
        // <ListHeaderItem
        //     key="date_use"
        //     name="date_use"
        //     isSort={false}
        // >
        //     <div className="header-row__center">
        //         <EventAvailableIcon/>
        //         <div className="header-row__center_text">Использован</div> 
        //     </div>
        // </ListHeaderItem>,
        <ListHeaderItem
            key="payment_type_id"
            name="payment_type_id"
        >
            <div className="header-row__center">
                <ContactlessIcon/>
                <div className="header-row__center_text">Тип оплаты</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="is_active"
            name="is_active"
            isSort={false}
        >
            <div className="header-row__center">
                <FontDownloadIcon/>
                <div className="header-row__center_text">Актив.</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
            className="create_mobile"
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>Номер</InputNumber>,
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Пользователь</InputText>,
        <InputPhone className="input_wf" useReg={false} value={_phone} onChange={(e) => { set_phone(e.target.value) }}>Телефон</InputPhone>,
        <InputText className="input_wf" value={_email} onChange={(e) => { set_email(e.target.value) }}>Email</InputText>,
        <InputText className="input_wf" value={_code} onChange={(e) => { set_code(e.target.value) }}>Код</InputText>,
        <MultiSelect className="input_wf options-w_150-r" options={products} value={_productIds} onChange={handleProductsChange}>Продукты</MultiSelect>,
        <InputText className="input_wf" value={_promocodeName} onChange={(e) => { set_promocodeName(e.target.value) }}>Промокод</InputText>,
        <MultiSelect className="input_wf options-w_150-r" options={payTypes} value={_payType} onChange={handlePayTypeChange}>Тип оплаты</MultiSelect>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-certificates_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="bookingCode" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>
                <a target="_blank" href={route("authorized.admin.booking.bookings.show", {id: row.booking_id})}>{row.booking_id}</a>
            </ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="name" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{row.name}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="phone" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>{row.phone}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="email" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>{row.email}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="code" data-label={ViewHandler.getComponentTextByClass(header[5].props.children, 'header-row__center_text')}>
                <a target="_blank" href={row.file}>{row.code}</a>
            </ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="price" data-label={ViewHandler.getComponentTextByClass(header[6].props.children, 'header-row__center_text')}>{row.total} ₽</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="product" data-label={ViewHandler.getComponentTextByClass(header[7].props.children, 'header-row__center_text')}>
                {row.products.map((product) => (
                    <div key={product.id}>
                        <div>Время: {product.minutes} мин.</div>
                        <div>{product.price} ₽</div>
                    </div>
                ))}
            </ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="promocode" data-label={ViewHandler.getComponentTextByClass(header[8].props.children, 'header-row__center_text')}>{row.promocode_name}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="status" data-label={ViewHandler.getComponentTextByClass(header[9].props.children, 'header-row__center_text')}>{row.status}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="expire" data-label={ViewHandler.getComponentTextByClass(header[10].props.children, 'header-row__center_text')}>
                <div className={`${row.date_end && row.date_end < new Date() ? 'admin-certificates__date--expired' : 'admin-certificates__date--valid'}`}>{DateLib.formatterDateTime(row.date_end)}</div>
            </ListBodyItem>,
            // <ListBodyItem className="admin-certificates_column-width" key="price" data-label={header[10].props.children}>
            //     <div className='admin-certificates__date--expired'>{DateLib.formatterDateTime(row.date_use)}</div>
            // </ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="payment_type_id" data-label={ViewHandler.getComponentTextByClass(header[12].props.children, 'header-row__center_text')}>{row.payment_type_name}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="is_active" data-label={ViewHandler.getComponentTextByClass(header[11].props.children, 'header-row__center_text')}>
                {
                    row.is_active ? <div className="is-active_icon--success"><VerifiedIcon style={{fill: 'rgb(80 157 207)' }}/></div>
                        : <div className="is-active_icon--danger"><NewReleasesIcon /></div>
                }
            </ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="show" >
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiOrderCertificates}
            renderRow={renderRow}
            useRowSelect={true}
            isActiomLoading={_isActionLoading}
            searchParams={
                { 
                    id: _id, 
                    name: _name, 
                    phone: _phone, 
                    email: _email, 
                    code: _code, 
                    price: _price, 
                    products: _productIds, 
                    promocode: _promocodeName ,
                    paymentTypeIds: _payType
                }
            }
            functions={
                {
                    'Отправить на Email': setMultiEmailData,
                    'Деактивировать': deactivateMultiCertificate,
                    'Активировать': activateMultiCertificate,
                    'Сгенерировать сертификаты': generateCertificate,
                }
            }
            colspanCount={15}
        />
        <MyModal className="certificate__email" isOpenModal={isModalOpen} closeModal={closeModal} title={"email"}>
            <div className="certificate__email_content">
                <InputText className="input_wf" value={_sendTo} onChange={(e) => { set_sendTo(e.target.value) }}>E-mail</InputText>,
                <Button onClick={sendMultiEmail}>Отправить</Button>
            </div>
        </MyModal>
    </>
}
