//Реакт
import React, { useLayoutEffect, useContext } from "react";
//Библиотеки и Api
import { route } from "../../../../../inc/Routes";
import ApiOrderAmo from "../../../../../app/api/authorized/admin/order/ApiOrderAmo";
import ViewHandler from "../../../../../inc/ViewHandler";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import ButtonLinkShow from "../../../../components/button-link/__show";
import Button from "../../../../components/button/button";
//Mui icons
import NumbersIcon from '@mui/icons-material/Numbers'; 
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import FmdBadIcon from '@mui/icons-material/FmdBad';
//Стили
import './styles.css';

export default function OrderAmoIndex({ ...other }) {
    return <Page {...other}>
        <OrderAmoContent {...other} />
    </Page>
}

function OrderAmoContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    useLayoutEffect(() => {
        contextPage.setTitle(`AmoCrm`);
        contextContent.setTitle(`AmoCrm`);
    }, [contextContent, contextPage]);

    const synchronizeData = async () => {
        try {
            const apiStore = ApiOrderAmo.Store({});
            const responseData = await apiStore.getResponse();
        } catch (error) {}
    };

    const header = [
        <ListHeaderItem
            key="id"
            name="id"
            isSort={false}
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Заказ</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="name"
            name="name"
            isSort={false}
        >
            <div className="header-row__center">
                <PersonIcon/>
                <div className="header-row__center_text">Пользователь</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="phone"
            name="phone"
            isSort={false}
        >
            <div className="header-row__center">
                <PhoneIcon/>
                <div className="header-row__center_text">Телефон</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="email"
            name="email"
            isSort={false}
        >
            <div className="header-row__center">
                <EmailIcon/>
                <div className="header-row__center_text">Email</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="type_name"
            name="type_name"
            isSort={false}
        >
            <div className="header-row__center">
                <WebStoriesIcon/>
                <div className="header-row__center_text">Тип</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="code"
            name="code"
            isSort={false}
        >
            <div className="header-row__center">
                <ReceiptLongOutlinedIcon/>
                <div className="header-row__center_text">Сертификат</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
           key="price"
           name="price"
           isSort={false}
        >
            <div className="header-row__center">
                <CurrencyRubleIcon/>
                <div className="header-row__center_text">Итого</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="product"
            name="product"
            isSort={false}
        >
            <div className="header-row__center">
                <InterestsOutlinedIcon/>
                <div className="header-row__center_text">Продукт</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="promocode_name"
            name="promocode_name"
            isSort={false}
        >
            <div className="header-row__center">
                <QrCode2OutlinedIcon/>
                <div className="header-row__center_text">Промокод</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="status"
            name="status"
            isSort={false}
        >
            <div className="header-row__center">
                <FmdBadIcon/>
                <div className="header-row__center_text">Статус</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="show"
            name="show"
            isSort={false}
        >
        </ListHeaderItem>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-amo_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="name" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>{row.name}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="phone" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{row.phone}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="email" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>{row.email}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="type_name" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>{row.type_name}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="code" data-label={ViewHandler.getComponentTextByClass(header[5].props.children, 'header-row__center_text')}><a href={row.certificate_file}>{row.code}</a></ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="price" data-label={ViewHandler.getComponentTextByClass(header[6].props.children, 'header-row__center_text')}>{row.total} ₽</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="product" data-label={ViewHandler.getComponentTextByClass(header[7].props.children, 'header-row__center_text')}>
                {row.products.map((product) => (
                <div key={product.id}>
                    <div>Время: {product.minutes}</div>
                    <div>Цена: {product.price} ₽ ({product.total} ₽)</div>
                </div>
                ))}
            </ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="promocode_name" data-label={ViewHandler.getComponentTextByClass(header[8].props.children, 'header-row__center_text')}>{row.promocode_name}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="status" data-label={ViewHandler.getComponentTextByClass(header[9].props.children, 'header-row__center_text')}>{row.status}</ListBodyItem>,
            // <ListBodyItem className="admin-amo_column-width" key="created_at" data-label={header[10].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
            // <ListBodyItem className="admin-amo_column-width" key="updated_at" data-label={header[11].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="show">
                {<ButtonLinkShow href={route(`authorized.admin.order.certificates.show`,{id: row.certificate_id})} />}
            </ListBodyItem>,
        ];
    }
    return <>
        <Button onClick={synchronizeData}>Синхронизация</Button>
        <List
            header={header}
            ApiModel={ApiOrderAmo}
            renderRow={renderRow}
        />
    </>
}
