import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";

export default class ApiBookingAnalytic extends ApiModelWithAuth{
    pathDef = 'authorized/admin/statistic/analytics/booking';

    index(params){
        this.__params = params;
        this.__path = ('index' in this.paths) ? this.paths.index : this.pathDef;
        this.formatterResponseData = (rdata) =>{
            return rdata.response.data;
        }
    }
}