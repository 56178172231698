//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
//Библиотеки и Api
import ApiBooking from "../../../../../app/api/authorized/admin/booking/ApiBooking";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputPhone from "../../../../components/inputs/phone/phone";
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import InputTextArea from "../../../../components/inputs/textarea/textarea";
import InputNumber from "../../../../components/inputs/number/number";
import ShowWrap from "../../../../components/wrappers/show-wrap";
import ShowWrapItem from "../../../../components/wrappers/show-wrap-item";
//Стили
import './styles.css';
import '../../../styles.css';
import { route } from "../../../../../inc/Routes";
import ButtonLink from "../../../../components/button-link/button-link";

export default function BookingShow({ ...other }) {
    return <Page {...other} ApiModel={ApiBooking}>
        <BookingShowContent {...other} />
    </Page>
}

function BookingShowContent({ ...other }) {
    const contextPage = useContext(ContextPage);
    const contextContent = useContext(ContextContent);
    const [_id, set_id] = useState(null);
    const [_minutes, set_minutes] = useState('')
    const [_countPeople, set_countPeople] = useState('')
    const [_dateStart, set_dateStart] = useState(null)
    const [_dateEnd, set_dateEnd] = useState(null)
    const [_comment, set_comment] = useState('')

    const [_orderId, set_orderId] = useState('')
    const [_orderTotal, set_orderTotal] = useState('')

    const [_userId, set_userId] = useState('')
    const [_userName, set_userName] = useState('')
    const [_userEmail, set_userEmail] = useState('')
    const [_userPhone, set_userPhone] = useState('')

    const [_typeName, set_typeName] = useState('')
    const [_statusName, set_statusName] = useState('')
    const [_checkStatusName, set_checkStatusName] = useState('')
    const [_paymentTypeName, set_paymentTypeName] = useState('')

    const [_balanceId, set_balanceId] = useState('')
    const [_balanceName, set_balanceName] = useState('')

    const [_created, set_created] = useState(null);
    const [_updated, set_updated] = useState(null);

    contextContent._dataToServer.current = { comment: _comment };
    
    useLayoutEffect(() => {
        contextContent._handleSetData.current = (data) => {
            set_id(data.id);
            set_userId(data?.user?.id)
            set_userName(data?.user?.name)
            set_userEmail(data?.user?.email)
            set_userPhone(data?.user?.phone)
            set_typeName(data?.type?.name)
            set_statusName(data?.status?.name)
            set_paymentTypeName(data?.payment_type?.name)
            set_minutes(data.minutes);
            set_dateStart(data.date_start);
            set_dateEnd(data.date_end);
            set_comment(data.comment);
            set_countPeople(data.count_people);
            set_orderId(data?.order?.id);
            set_orderTotal(data?.order?.total);
            set_balanceId(data?.balance?.id)
            set_balanceName(data?.balance?.name)
            set_checkStatusName(data?.check_status?.name)
            set_created(data.created_at);
            set_updated(data.updated_at);
        
            contextPage.setTitle(`Полетное время: ${data.id}`);
            contextContent.setTitle(`Полетное время: ${data.id}`);
            contextPage.setName(data.id);
        };
        contextContent.doLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <>
    <ShowWrap>
        <ShowWrapItem>
            <InputWrapper>
                <InputNumber className="pages__show-input" value={_id} readOnly={true}>Номер</InputNumber>
            </InputWrapper>
            <InputWrapper>
                <InputText className="pages__show-input" value={_userName} readOnly={true}>Имя</InputText>
            </InputWrapper>
            <InputWrapper>
                <InputText className="pages__show-input" value={_userEmail} readOnly={true}>Почта</InputText>
            </InputWrapper>
            <InputWrapper>
                <InputPhone className="pages__show-input" value={_userPhone} readOnly={true} >Телефон</InputPhone>
            </InputWrapper>
            <InputWrapper>
                <InputText className="pages__show-input" value={_typeName} readOnly={true}>Тип бронирования</InputText>
            </InputWrapper>
            <InputWrapper>
                <InputText className="pages__show-input" value={_statusName} readOnly={true}>Статус бронирования</InputText>
            </InputWrapper>
            <InputWrapper>
                <InputText className="pages__show-input" value={_paymentTypeName} readOnly={true}>Тип оплаты</InputText>
            </InputWrapper>
            <InputWrapper>
                <InputText className="pages__show-input" value={_minutes} readOnly={true}>Минуты</InputText>
            </InputWrapper>
            <InputWrapper>
                <InputDatetime className="pages__show-input" value={_dateStart} readOnly={true}>Начало</InputDatetime>
            </InputWrapper>
            <InputWrapper>
                <InputDatetime className="pages__show-input" value={_dateEnd} readOnly={true}>Окончание</InputDatetime>
            </InputWrapper>
            <InputWrapper>
                <InputTextArea className="pages__show-input" value={_comment} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_comment(e.target.value)}>Комментарий</InputTextArea>
            </InputWrapper>
        </ShowWrapItem>
        <ShowWrapItem>
            <InputWrapper>
                <InputText className="pages__show-input" value={_countPeople} readOnly={true}>Количество человек</InputText>
            </InputWrapper>
            {_orderId ?
                <>
                    <InputWrapper>
                        <InputText className="pages__show-input" value={_orderId} readOnly={true}>
                            Номер заказа
                        </InputText>
                        <ButtonLink className="pages__show-btn-link" href={route(`authorized.admin.order.bookings.show`,{id: _orderId})}/>
                    </InputWrapper>
                    <InputWrapper>
                        <InputText className="pages__show-input" value={_orderTotal} readOnly={true}>Стоимость заказа</InputText>
                    </InputWrapper>
                </>
            : null }
            {_balanceId ?
                <>
                    <InputWrapper>
                        <InputText className="pages__show-input" value={_balanceId} readOnly={true}>
                            Номер депозита
                        </InputText>
                        {/* <ButtonLink className="pages__show-btn-link" href={route(`authorized.admin.booking.balances.index`, {id: _balanceId})}/> */}
                    </InputWrapper>
                    <InputWrapper>
                        <InputText className="pages__show-input" value={_balanceName} readOnly={true}>Тип депозита</InputText>
                    </InputWrapper>
                </>
            : null }
            <InputWrapper>
                <InputText className="pages__show-input" value={_checkStatusName} readOnly={true}>Проверка</InputText>
            </InputWrapper>
            <InputWrapper>
                <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
            </InputWrapper>
            <InputWrapper>
                <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
            </InputWrapper>
        </ShowWrapItem>
    </ShowWrap>
    </>
}
