//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
//Библиотеки и Api
import ApiOrderCertificates from "../../../../../app/api/authorized/admin/order/ApiOrderCerificates";
import ApiUser from "../../../../../app/api/authorized/admin/user/ApiUser";
import DateLib from "../../../../../inc/DateLib";
import { route } from "../../../../../inc/Routes";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
import { ContextApp } from "../../../../modules/app/appContext";
//Компоненты
import Select from "../../../../components/inputs/select"
import InputText from "../../../../components/inputs/text";
import InputPhone from "../../../../components/inputs/phone/phone";
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import InputTextArea from "../../../../components/inputs/textarea/textarea";
import SelectApi from "../../../../components/inputs/select-api";
import InputNumber from "../../../../components/inputs/number/number";
import ButtonLink from "../../../../components/button-link/button-link";
import InputCheckbox from "../../../../components/inputs/checkbox/checkbox";
import ShowWrap from "../../../../components/wrappers/show-wrap";
import ShowWrapItem from "../../../../components/wrappers/show-wrap-item";
//Стили
import './styles.css';
import '../../../styles.css';

export default function OrderCertificateShow({ ...other }) {
    return <Page className="content-overflow-visible" {...other} ApiModel={ApiOrderCertificates}>
        <OrderCertificateShowContent {...other} />
    </Page>
}

function OrderCertificateShowContent({ ...other }) {
    const contextPage = useContext(ContextPage);
    const contextContent = useContext(ContextContent);
    const { checkPermission } = useContext(ContextApp);

    const [searchParams, setSearchParams] = useSearchParams();
    const [_id, set_id] = useState(null);
    const [_name, set_name] = useState(contextContent.isCreate ?  searchParams.get("name") ?? '' : '');
    const [_phone, set_phone] = useState(contextContent.isCreate ?  searchParams.get("phone") ?? '' : '');
    const [_email, set_email] = useState(contextContent.isCreate ?  searchParams.get("email") ?? '' : '');
    const [_bookings, set_bookings] = useState({});
    const [_productId, set_productId] = useState('');
    const [_payStatusId, set_payStatusId] = useState('');
    const [_count, set_count] = useState(1);
    const [_comment, set_comment] = useState('');
    const [_code, set_code] = useState('');
    const [_promocode, set_promocode] = useState('');
    const [_sum, set_sum] = useState(null);
    const [_total, set_total] = useState(null);
    const [_payDate, set_payDate] = useState(null);
    const [_endDate, set_endDate] = useState(null);
    const [_useDate, set_useDate] = useState(null)
    const [_isActive, set_isActive] = useState(true)
    const [_sendTo, set_sendTo] = useState('');
    const [_created, set_created] = useState(null);
    const [_updated, set_updated] = useState(null);

    const products = useSelector(state => state.adminOption.products);
    const paymentStatuses = useSelector(state => state.adminOption.paymentStatusesCertificate);

    const handleProductChange = (event) => {
        set_productId(event.target.value);
    };

    const handlePaymenthange = (event) => {
        set_payStatusId(event.target.value);
    };

    const handleIsActiveChange = (event) => {
        set_isActive(Number(!_isActive));
    };

    contextContent._dataToServer.current = {
        name: _name,
        phone: _phone,
        email: _email,
        product_id: _productId,
        comment: _comment,
        count: _count,
        payment: _payStatusId,
        date_end: _endDate,
        date_pay: _payDate,
        date_use: _useDate,
        is_active: _isActive
    };
    useLayoutEffect(() => {
        contextContent._handleSetData.current = (data) => {
            set_id(data.id);
            set_name(data.name);
            set_phone(data.phone);
            set_email(data.email);
            set_code(data.code)
            set_productId(data.product);
            set_promocode(data.promocode_name)
            set_payStatusId(data.payment_id)
            set_comment(data.comment);
            set_sum(data.sum);
            set_total(data.total);
            set_payDate(data.date_pay);
            set_endDate(data.date_end);
            set_useDate(data.date_use);
            set_isActive(data.is_active);
            set_bookings(data.booking);
            set_created(data.created_at);
            set_updated(data.updated_at);
            contextPage.setTitle(`Сертификат: ${data.name}`);
            contextContent.setTitle(`Сертификат: ${data.name}`);
            contextPage.setName(data.name);
        };
        contextContent.doLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        <ShowWrap>
            <ShowWrapItem>
            {_bookings && Object.keys(_bookings).length > 0 ? <h3>Сертификат</h3> : null}
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputNumber className="pages__show-input" value={_id} readOnly={true}>Номер</InputNumber>
                </InputWrapper> 
            : null}
            <InputWrapper>
                <SelectApi 
                    className="pages__show-input" 
                    apiModel={ApiUser} 
                    value={_name} 
                    searchParam= "name" 
                    textParams = {['surname', 'email', 'phone']}
                    countSearch={4} 
                    onChange={e => set_name(e.target.value)}
                    handleRowSelect={row => {
                        row.name && set_name(row.name);
                        row.phone && set_phone(row.phone);
                        row.email && set_email(row.email);
                    }} 
                    readOnly={!contextContent.isCreate} 
                    placeholder="Имя"
                >Имя</SelectApi>
            </InputWrapper>
            <InputWrapper>
                <InputPhone className="pages__show-input" value={_phone} readOnly={!contextContent.isCreate} onChange={e => set_phone(e.target.value)}>Телефон</InputPhone>
            </InputWrapper>
            <InputWrapper>
                <InputText className="pages__show-input" value={_email} readOnly={!contextContent.isCreate} onChange={e => set_email(e.target.value)}>Почта</InputText>
            </InputWrapper>
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputText className="pages__show-input" value={_code} readOnly={true}>Код сертификата</InputText>
                </InputWrapper>
            : null}
            <InputWrapper>
                <Select className="pages__show-input" options={products} value={_productId} readOnly={!contextContent.isCreate} onChange={handleProductChange}>Продукт</Select>
            </InputWrapper>
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputText className="pages__show-input" value={_promocode} readOnly={true}>Промокод</InputText>
                </InputWrapper>
            : null}
            <InputWrapper>
                <Select className="pages__show-input" options={paymentStatuses} value={_payStatusId} readOnly={!contextContent.isCreate} onChange={handlePaymenthange}>Статус оплаты</Select>
            </InputWrapper>
            {!contextContent.isShowOrEdit ?
                <InputWrapper>
                    <InputNumber className="pages__show-input" value={_count} readOnly={!contextContent.isCreate} onChange={e => set_count(e.target.value)}>Количество</InputNumber>
                </InputWrapper>
            : null}
            </ShowWrapItem>
            <ShowWrapItem>
            <InputWrapper>
                <InputTextArea className="pages__show-input" value={_comment} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_comment(e.target.value)}>Комментарий</InputTextArea>
            </InputWrapper>
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputText className="pages__show-input" value={_sum} readOnly={true}>Сумма, ₽</InputText>
                </InputWrapper>
            : null}
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputText className="pages__show-input" value={_total} readOnly={true}>Итог, ₽</InputText>
                </InputWrapper>
            : null}
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputDatetime className="pages__show-input" value={_payDate} readOnly={true}>Дата оплаты</InputDatetime>
                </InputWrapper>
            : null}
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputDatetime className="pages__show-input" value={_endDate} readOnly={!contextContent.isEdit} onChange={e => set_endDate(e.target.value)}>Дата окончания сертификата</InputDatetime>
                </InputWrapper>
            : null}
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputDatetime className="pages__show-input" value={_useDate} readOnly={!contextContent.isEdit} onChange={e => set_useDate(e.target.value)}>Дата использования сертификата</InputDatetime>
                </InputWrapper>
            : null}
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
                </InputWrapper>
            : null}
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
                </InputWrapper>
            : null}
            {!contextContent.isCreate ?
                <InputWrapper>
                    <InputCheckbox className="pages__show-input" value={_isActive} readOnly={!contextContent.isEdit}  onChange={handleIsActiveChange}>Активация</InputCheckbox>
                </InputWrapper>
            : null}
            </ShowWrapItem>
        </ShowWrap>
        {/* {!contextContent.isCreateOrEdit && Object.keys(_bookings).length > 0 ?
            <div>
                <h3>Бронирования</h3>
                <InputWrapper>
                    <InputText className="pages__show-input" value={_bookings.id} readOnly={true}>Номер</InputText>
                    {checkPermission("admin.booking.bookings.show") ? <ButtonLink className="pages__show-btn-link" href={route("authorized.admin.booking.bookings.show", {id: _bookings.id})}/> : null}
                </InputWrapper>
                <InputWrapper>
                    <InputText className="pages__show-input" value={DateLib.formatterDateTime(_bookings?.slotTimeEnd?.date_start ?? null)} readOnly={true}>Начало</InputText>
                </InputWrapper>
                <InputWrapper>
                    <InputText className="pages__show-input" value={_bookings.count_people} readOnly={true}>Количество человек</InputText>
                </InputWrapper>
            </div>
        : null} */}
    </>
}
