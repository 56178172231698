import React from "react";

export default class ViewHandler{
    static getComponentTextByClass(header, className) {
        // Ищем элемент с указанным классом и возвращаем его текст
        const foundHeader = React.Children.toArray(header).find(child => {
            return React.isValidElement(child) && 
                   child.props.children && 
                   React.Children.toArray(child.props.children).some(grandchild => 
                       React.isValidElement(grandchild) && 
                       grandchild.props.className === className
                   );
        });
    
        return foundHeader ? React.Children.toArray(foundHeader.props.children).find(grandchild => 
            React.isValidElement(grandchild) && grandchild.props.className === className
        ).props.children : '';
    };
}
