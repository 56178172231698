import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class FileFormatter extends Formatter{}
export default class ApiFile extends ApiModelWithAuth{
    pathDef = 'authorized/admin/content/files';

    store(params){
        this.__type = "POST";
        this.__subtype = "formData";
        this.isSendFile = true;
        this.__path = ('store' in this.paths) ? this.paths.store : this.pathDef;
        this.__params = this.getParamsForStore(params);
        this.formatterResponseData = (rdata) =>{
            return rdata.response.data;
        }
    }

    static async DeleteFile(params){
        const api = new this({});
        api.deleteFile(params);
        let rdata = await api.getResponse();
        if(rdata){
            const data = rdata.response
            return data;
        }
        return null;
    }
    deleteFile(params){
        this.__type = "POST";
        this.__path = 'authorized/admin/content/files/deleteFile';
        this.__params = params;
    }

    formatterItem = FileFormatter.Item;
}

