//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
//Библиотеки и Api
import ViewHandler from "../../../../../inc/ViewHandler";
import ApiPlatform from "../../../../../app/api/authorized/admin/event/ApiPlatform";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
import DashboardIcon from '@mui/icons-material/Dashboard';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
//Mui icons
import NumbersIcon from '@mui/icons-material/Numbers';
//Стили
import './styles.css';

export default function PlatformIndex({ ...other }) {
    return <Page {...other}>
        <PlatformIndexContent {...other} />
    </Page>
}

function PlatformIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_name, set_name] = useState('');

    useLayoutEffect(() => {
        contextPage.setTitle(`Площадки`);
        contextContent.setTitle(`Площадки`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Номер</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="name"
            name="name"
        >
            <div className="header-row__center">
                <DashboardIcon/>
                <div className="header-row__center_text">Платформа</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="description"
            name="description"
        >
            <div className="header-row__center">
                <TextSnippetIcon/>
                <div className="header-row__center_text">Описание</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
            className="create_mobile"
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>Номер</InputNumber>,
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Название</InputText>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-platforms_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-platforms_column-width" key="name" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>{row.name}</ListBodyItem>,
            <ListBodyItem className="admin-platforms_column-width" key="description" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{row.description}</ListBodyItem>,
            <ListBodyItem className="admin-platforms_column-width" key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiPlatform}
            renderRow={renderRow}
            searchParams={{ id: _id, name: _name }}
        />
    </>
}
