// React
import React, { useState, useEffect } from "react";
// Styles
import "./styles.css";


export default function RadioAnswers({ question, clientAnswer, readOnly, onOtherChange, onOtherInputChange }) {
  const [selected_value, set_selected_value] = useState('');
  const [free_answer_value, set_free_answer_value] = useState('');
  
  useEffect(() => {
    if (clientAnswer) {
      if (clientAnswer.answer_id) {
        set_selected_value(clientAnswer.answer_id);
        onOtherChange(clientAnswer.question_id, clientAnswer.answer_id);
      }else{
        set_free_answer_value(clientAnswer.value);
        set_selected_value('freeAnswer'); // Устанавливаем значение для свободного ответа
        //onOtherInputChange(clientAnswer.question_id, clientAnswer.value);
      }
    }
  }, [clientAnswer]);

  const handleRadioChange = (event) => {
    const value = event.target.value;
    set_selected_value(value);
    if(value === 'freeAnswer') {
      onOtherInputChange(question.id, free_answer_value);
    }else if(value === 'Не заполнено'){
      onOtherChange(event.target.name, 'Не заполнено');
    }
    else{
      onOtherChange(event.target.name, event.target.value);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    set_free_answer_value(value);
    onOtherInputChange(event.target.name, value);
  };
  
  return (
    <div className="radio__answers"> 
      {question.answers.map((answer, index) => (
        <div key={answer.id}>
          <label className="dictionary_answers">
            <input 
              className="dictionary_answers__radio"
              type="radio" 
              name={question.id} 
              value={answer.id} 
              checked={selected_value == answer.id}
              onChange={handleRadioChange}
              disabled={readOnly} 
            />
            <span className="dictionary_answers__radio-text">{answer.value}</span>
          </label>
          {question.is_value && index === question.answers.length - 1 ? (
            <>
              <label className="free_answer__label">
                <input 
                  className="free_answer__radio"
                  type="radio" 
                  name={question.id} 
                  value="freeAnswer" 
                  checked={selected_value === 'freeAnswer'}
                  onChange={handleRadioChange} 
                  disabled={readOnly} 
                />
                <span className="free_answer__radio-text">{question.value_label ? question.value_label : "Другое:"}</span>
              </label>
              <input 
                className="free_answer__input input pages__show-input"
                type="text" 
                name={question.id} 
                value={free_answer_value}
                onChange={handleInputChange}
                data-hide={selected_value !== "freeAnswer"}  
                readOnly={readOnly}
              />
            </>
          ) : null}
        </div>
      ))}
    </div>
  );
}
