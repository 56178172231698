import { Quill } from 'react-quill';
//API
import ApiFile from '../../../../app/api/authorized/admin/content/ApiFile';

const Parchment = Quill.import('parchment');

const ATTRIBUTES = [
  'alt',
  'height',
  'width'
];


class Image extends Parchment.Embed {
  static create(value) {
    let node = super.create(value);

    if (value.startsWith('data:image')){
      let file = dataURLToFile(value);
      uploadImage(file)
        .then(res => {
          const imageUrl = res.file_url;
          node.setAttribute('src', this.sanitize(imageUrl));
        })
        .catch(error => {
          console.error('Ошибка при загрузке изображения:', error);
          node.setAttribute('src', '//:0');
        });
    }else{
      node.setAttribute('src', this.sanitize(value));
    }

    return node;
  }

  static formats(domNode) {
    return ATTRIBUTES.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static match(url) {
    return /\.(jpe?g|gif|png)$/.test(url) || /^data:image\/.+;base64/.test(url);
  }

  static sanitize(url) {
    return sanitize(url, ['http', 'https', 'data']) ? url : '//:0';
  }

  static value(domNode) {
    return domNode.getAttribute('src');
  }

  static deleteAt(domNode){
    return domNode.getAttribute('src');
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }

  deleteAt(index, length){
    const imageUrl = this.domNode.getAttribute('src');
    
    if (imageUrl) {
        deleteImage(imageUrl);
    }
   super.deleteAt(index, length);
   this.cache = {};
  }
}
Image.blotName = 'image';
Image.tagName = 'IMG';
Image.className = 'ql-img';

const uploadImage = async (file) => {
    try{
      let response = await ApiFile.Store({file: file});
      let data = await response.getResponse();
      return data;
    }catch(error){
      console.error('Ошибка при получении данных:', error);
    }
    return null;
  }

const deleteImage = async (url) =>{
  if(!url.startsWith('http')) return null;
  const fileName = getFileName(url);
  try{
      await ApiFile.DeleteFile({file: fileName});
  }catch(error){
    console.error('Ошибка при получении данных:', error);
  }
  return null;
}

const getFileName = (url) => {
  const segments = url.split('/');
  return segments[segments.length - 1];
};

function dataURLToFile(dataUrl, filename = 'image.png') {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
  }
  const blob = new Blob([u8arr], { type: mime });

  return new File([blob], filename, { type: mime });
}

function sanitize(url, protocols) {
    let anchor = document.createElement('a');
    anchor.href = url;
    let protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
    return protocols.indexOf(protocol) > -1;
  }

Quill.register('formats/image', Image);

export default Image;
